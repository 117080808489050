<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-buttons *ngIf="canDismiss" slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/icon/ic_close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title size="large">
      {{ findModalTitle() }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<efp-modal-form [dismissable]="false">
  <div class="modal-inner">
    <form [formGroup]="gdprForm" *ngIf="!privacyUpdateSuccess">
      <div
        *ngIf="shouldShowCloudSaveAcceptance"
        [ngClass]="index !== 0 ? 'hidden' : ''"
        class="d-flex-col gap-16 p-bottom-24">
        <ion-text>
          {{ 'LEGAL_MODAL.SITE_CLOUD_SAVE.DESCRIPTION' | translate }}
        </ion-text>

        <ion-item lines="none" class="link-container">
          <ion-text>{{ 'LEGAL_MODAL.SITE_CLOUD_SAVE.ACCEPT' | translate }}</ion-text>
          <ion-toggle
            labelPlacement="end"
            [checked]="cloudSaveAccepted"
            (ionChange)="setCloudSave($event)"></ion-toggle>
        </ion-item>

        <ion-item
          button
          (click)="openProfilManagement()"
          lines="none"
          data-test-selector="homepage-menu-delete-account">
          <ion-label class="delete-label" color="danger">{{
            'AUTH.DELETE_ACCOUNT' | translate
          }}</ion-label>
        </ion-item>
      </div>

      <div [ngClass]="{ hidden: index !== countrySiteIndex }" class="d-flex-col">
        <ion-text class="info-text">{{ 'LEGAL_MODAL.SITE_1.DESCRIPTION' | translate }}</ion-text>

        <ion-text class="country-title">{{ 'SETTINGS.COUNTRY' | translate }}</ion-text>
        <div class="d-flex-col form-container d-flex-responsive-wrap d-flex-responsive-gap">
          <ion-select
            value="at"
            formControlName="country"
            tabindex="-1"
            interface="popover"
            placeholder="Select country"
            label-placement="start"
            fill="outline"
            justify="start"
            class="flex-1"
            [interfaceOptions]="{ side: 'bottom', size: 'cover', cssClass: 'select-popover' }"
            cancelText="{{ 'GENERAL.CANCEL' | translate }}"
            data-test-selector="appsettings-country-select">
            <ion-select-option
              *ngFor="let country of countries"
              value="{{ country }}"
              attr.data-test-selector="appsettings-country-selection-{{ country }}">
              {{ 'COUNTRY.' + country.toUpperCase() | translate }}
            </ion-select-option>
          </ion-select>
        </div>

        <ion-text class="usage-text">{{ 'LEGAL_MODAL.SITE_1.USAGE_INFO' | translate }}</ion-text>
        <ion-radio-group value="bug" formControlName="privacyType">
          <ion-item lines="none">
            <ion-radio
              data-test-selector="privacy-policy-personal"
              labelPlacement="end"
              value="PERSONAL"
              justify="start">
              <span class="wrap-label-radio-span">{{
                'LEGAL_MODAL.SITE_1.USAGE_PERSONAL' | translate
              }}</span>
            </ion-radio>
          </ion-item>
          <ion-item lines="none">
            <ion-radio
              data-test-selector="privacy-policy-business"
              labelPlacement="end"
              value="BUSINESS"
              justify="start">
              <span class="wrap-label-radio-span">{{
                'LEGAL_MODAL.SITE_1.USAGE_BUSINESS' | translate
              }}</span>
            </ion-radio>
          </ion-item>
        </ion-radio-group>
      </div>

      <div
        [ngClass]="index !== gdprSiteIndex ? 'hidden' : ''"
        class="d-flex-col gap-24 p-bottom-24">
        <ion-text>
          {{ 'LEGAL_MODAL.SITE_2.CONFIRMATION' | translate }}
        </ion-text>

        <ion-text>
          {{ 'LEGAL_MODAL.SITE_2.DESCRIPTION' | translate }}
        </ion-text>

        <ion-item lines="none" class="link-container">
          <ion-text>{{ 'LEGAL_MODAL.SITE_2.ACCEPT_PREFIX' | translate }}</ion-text>
          <a class="p-left-4" target="_blank" href="{{ gdprUrl }}">{{
            'LEGAL_MODAL.SITE_2.GDRP' | translate
          }}</a>
          <ion-toggle
            labelPlacement="end"
            [checked]="gdprAccepted"
            (ionChange)="setGDPR($event)"
            data-test-selector="privacy-modal-gdpr"></ion-toggle>
        </ion-item>
        <ion-item lines="none" class="link-container">
          <ion-text>{{ 'LEGAL_MODAL.SITE_2.ACCEPT_PREFIX' | translate }}</ion-text>
          <a class="p-left-4" target="_blank" href="{{ dtdsUrl }}">{{
            'LEGAL_MODAL.SITE_2.DTS' | translate
          }}</a>
          <ion-toggle
            labelPlacement="end"
            [checked]="dtdsAccepted"
            (ionChange)="setDTDS($event)"
            data-test-selector="privacy-modal-dtds"></ion-toggle>
        </ion-item>
      </div>
    </form>
  </div>
</efp-modal-form>

<efp-steps-modal-footer *ngIf="!privacyUpdateSuccess">
  <ion-button
    button-start
    *ngIf="index !== 0"
    (click)="goToSlide(this.index - 1)"
    class="navigation-button light"
    data-test-selector="privacy-modal-prev-slide">
    <ion-icon slot="icon-only" src="assets/icon/ic_arrow-left.svg"></ion-icon>
  </ion-button>
  <ng-container status-indicators>
    <ion-icon
      (click)="goToSlide(0)"
      data-test-selector="feedback-modal-hint-status-indicator"
      aria-hidden="true"
      src="assets/icon/ellipse.svg"
      [ngClass]="{ active: index === 0 }"></ion-icon>
    <ion-icon
      (click)="goToSlide(1)"
      data-test-selector="feedback-modal-hint-status-indicator"
      aria-hidden="true"
      [ngClass]="{ active: index === 1 }"
      src="assets/icon/ellipse.svg"></ion-icon>
    <ion-icon
      *ngIf="shouldShowCloudSaveAcceptance"
      (click)="goToSlide(2)"
      data-test-selector="feedback-modal-hint-status-indicator"
      aria-hidden="true"
      [ngClass]="{ active: index === 2 }"
      src="assets/icon/ellipse.svg"></ion-icon>
  </ng-container>
  <ng-container button-end>
    <ion-button
      *ngIf="index === 0 && shouldShowCloudSaveAcceptance"
      [disabled]="!cloudSaveAccepted"
      (click)="goToSlide(this.index + 1)"
      class="navigation-button dark"
      data-test-selector="feedback-modal-next-slide-cloud-save">
      <ion-icon color="white" slot="icon-only" src="assets/icon/ic_arrow-right.svg"></ion-icon>
    </ion-button>
    <ion-button
      *ngIf="index === countrySiteIndex"
      [disabled]="!privacyTypeValid || !privacyPolicy"
      (click)="goToSlide(this.index + 1)"
      class="navigation-button dark"
      data-test-selector="privacy-modal-next-slide-country-side">
      <ion-icon color="white" slot="icon-only" src="assets/icon/ic_arrow-right.svg"></ion-icon>
    </ion-button>
    <ion-button
      (click)="onSubmit()"
      *ngIf="index === gdprSiteIndex"
      [disabled]="requestRunning || !gdprAccepted || !dtdsAccepted"
      class="navigation-button dark"
      data-test-selector="privacy-modal-submits">
      <ion-icon color="white" slot="icon-only" src="assets/icon/check.svg"></ion-icon>
    </ion-button>
  </ng-container>
</efp-steps-modal-footer>

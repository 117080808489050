import paper from 'paper/dist/paper-core'
import { CycleSymbol } from '../model'
import { InteractionState } from '../../../pages/planner/types/interactionState'

/**
 * This class describes an independent cycle symbol for walls paths that don't have cycle boundaries
 */
export class CycleSymbolDrawable {
  public position: paper.Point
  public cycleNumber: number
  public state: InteractionState

  constructor(cycleSymbol: CycleSymbol) {
    this.position = new paper.Point(cycleSymbol.position)
    this.cycleNumber = cycleSymbol.cycleNumber
    this.state = cycleSymbol.state
  }

  public toCycleSymbol(): CycleSymbol {
    return {
      position: {
        x: this.position.x,
        y: this.position.y,
      },
      cycleNumber: this.cycleNumber,
      state: this.state,
    }
  }

  public setState(state: InteractionState, resetSelected = false): void {
    if (
      (this.state === InteractionState.ENABLED &&
        (state === InteractionState.HOVER || state === InteractionState.SELECTED)) ||
      (this.state === InteractionState.HOVER &&
        (state === InteractionState.ENABLED || state === InteractionState.SELECTED)) ||
      (this.state === InteractionState.SELECTED &&
        state === InteractionState.ENABLED &&
        resetSelected)
    ) {
      this.state = state
    }
  }
}
